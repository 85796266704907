import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/Layout"

class TagTemplate extends Component {
  render() {
    const tag = this.props.data.tag
    const posts = this.props.data.posts

    return (
      <Layout>
        <h1 dangerouslySetInnerHTML={{ __html: tag.name }} />
        <div dangerouslySetInnerHTML={{ __html: tag.description }} />
        <p>{tag.count} posts</p>

        <ul>
          {posts.edges.map(({ node: { title, excerpt, fields: { slug } } }) => (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
              <div dangerouslySetInnerHTML={{ __html: excerpt }} />
            </li>
          ))}
        </ul>
      </Layout>
    )
  }
}

TagTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default TagTemplate

export const pageQuery = graphql`
  query($id: String!) {
    tag: wordpressTag(id: { eq: $id }) {
      slug
      name
      description
      count
    }

    posts: allWordpressPost(filter: {
      tags: { elemMatch: { id: { eq: $id } } }
    }) {
      edges {
        node {
          slug
          title
          excerpt
          fields {
            slug
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
